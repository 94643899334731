import React from "react";
import getPublications, { Publication } from "../../core/interfaces/publications";

function printBibtex(publication: Publication): string {
  const date = new Date(publication.date_published);
  let retstring = `@article{${publication.id},\n`;
  retstring += `    title={${publication.title}},\n`;
  retstring += `    author={${publication.authors.map((x) => x.name).join(" and ")}},\n`;
  retstring += `    journal={${publication.conference.name} (${publication.conference.id})},\n`;
  retstring += `    year={${date.getFullYear()}},\n`;
  if (publication.pages)
    retstring += `    pages={${publication.pages.start}, ${publication.pages.end}}\n`;
  retstring += `}`;
  return retstring;
}

export default function PublicationsBlock(props: {
  color: string;
  colorDark: string;
  preColor: string;
  preColorDark: string;
}): React.ReactElement {
  const { color, colorDark, preColor, preColorDark } = props;
  const publications = getPublications();
  const bibtexStrings = publications.map(printBibtex);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  if (typeof document !== "undefined") {
    document.onmouseup = (event: any) => {
      const targetId: string = event.target.id;
      if (event.target.id.startsWith("bibtex_modal_")) {
        document.getElementById(targetId).style.display = "none";
      }
    };
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  return (
    <section className={`pb-40 relative bg-${color} dark:bg-${colorDark}`}>
      <div
        className={`-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20 bg-${preColor} dark:bg-${preColorDark}`}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-${color} dark:text-${colorDark} fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div className="container mx-auto mt-6">
        <div className="flex flex-wrap items-start">
          <div className="w-full xl:w-1/3 mt-12 mx-auto">
            <h2
              id="publications"
              className="font-semibold text-5xl text-center text-gray-700 dark:text-gray-200"
            >
              Publications
            </h2>
          </div>

          <div className="w-full xl:w-2/3 mx-auto">
            {publications.map((publication, pub_ix) => (
              <div className="w-full px-8 py-8 mr-auto ml-auto" key={pub_ix}>
                <div className="relative flex flex-wrap lg:flex-row break-words w-full mb-6 shadow-lg rounded-lg bg-blue-800 hover:shadow-xl transition-all">
                  <div className="w-full lg:w-1/3 h-40 lg:h-auto">
                    <video
                      src={publication.featured_image.publicURL}
                      className="w-full h-full object-cover rounded-t-lg lg:rounded-l-lg"
                      autoPlay
                      loop
                    >
                      <p>Your browser does not support HTML5 video</p>
                    </video>
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-0 right-2/3 h-full w-26-px hidden lg:block"
                      viewBox="0 0 50 583"
                    >
                      <polygon
                        className="text-blue-800 fill-current"
                        points="50,0 50,583 0,583"
                      ></polygon>
                    </svg>
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute right-0 top-136-px w-full h-26-px block lg:hidden"
                      viewBox="0 0 583 25"
                    >
                      <polygon
                        points="0,25 583,25 583,0"
                        className="text-blue-800 fill-current"
                      ></polygon>
                    </svg>
                  </div>

                  <div className="w-full lg:w-2/3 h-auto p-4">
                    <h4 className="text-xl font-bold text-white">{publication.title}</h4>
                    <hr />

                    <h4 className="text-sm italic text-white text-right">
                      {publication.authors.map((author, author_ix, { length }) => (
                        <a href={author.url} target="_blank" key={author_ix}>
                          <span className={author.name == "Davi Frossard" ? "font-bold" : ""}>
                            {author.name}
                            {author_ix != length - 1 && ", "}
                          </span>
                        </a>
                      ))}
                    </h4>

                    <p className="text-md font-light mt-3 text-white mb-8">
                      {publication.abstract}
                    </p>

                    <p className="absolute bottom-0 right-0 text-md font-light p-3 mt-auto text-white text-right">
                      {publication.links.map((link, link_ix) => (
                        <a href={link.url} target="_blank" key={link_ix}>
                          <button
                            type="button"
                            className="bg-white text-gray-900 font-bold text-sm h-6 w-14 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 hover:text-gray-600 transition-all"
                          >
                            {link.name.toUpperCase()}
                          </button>
                        </a>
                      ))}

                      <button
                        type="button"
                        className="bg-white text-gray-900 font-bold text-sm h-6 w-14 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 hover:text-gray-600 transition-all"
                        onClick={() =>
                          (document.getElementById(`bibtex_modal_${pub_ix}`).style.display = "")
                        }
                      >
                        CITE
                      </button>
                    </p>
                  </div>
                </div>
                <div
                  id={`bibtex_modal_${pub_ix}`}
                  style={{ display: "none" }}
                  className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                >
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                        <h3 className="text-3xl font-semibold">BibTeX</h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() =>
                            (document.getElementById(`bibtex_modal_${pub_ix}`).style.display =
                              "none")
                          }
                        >
                          <span className="bg-transparent text-black opacity-75 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            x
                          </span>
                        </button>
                      </div>
                      <div
                        id={`bibtex_string_${pub_ix}`}
                        className="relative p-6 flex-auto whitespace-pre-wrap text-gray-600 text-sm leading-relaxed"
                      >
                        {bibtexStrings[pub_ix]}
                      </div>
                      <p className="text-md font-light p-3 mt-auto text-white text-right">
                        <button
                          type="button"
                          id={`bibtex_copy_${pub_ix}`}
                          className="bg-white text-gray-900 font-bold text-sm h-6 w-14 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 hover:text-gray-600 transition-all"
                          onClick={() => {
                            const btnEl = document.getElementById(`bibtex_copy_${pub_ix}`);
                            navigator.clipboard.writeText(bibtexStrings[pub_ix]).then(() => {
                              btnEl.innerText = "COPIED";
                              setTimeout(() => (btnEl.innerText = "COPY"), 3000);
                            });
                          }}
                        >
                          COPY
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
