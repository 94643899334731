import { graphql, useStaticQuery } from "gatsby";
import { Author, Conferece, Link, Pages, Photo } from "./primitives";

export interface Publication {
  id: string;
  title: string;
  date_published: string;
  conference: Conferece;
  links: Link[];
  authors: Author[];
  featured_image: Photo;
  abstract: string;
  pages?: Pages;
}

export default function getPublications(): Publication[] {
  const { allPapersYaml } = useStaticQuery(graphql`
    query GetPapers {
      allPapersYaml(sort: { fields: date_published, order: DESC }) {
        edges {
          node {
            id
            title
            date_published
            conference {
              id
              name
            }
            links {
              name
              url
            }
            authors {
              name
              url
            }
            featured_image {
              publicURL
            }
            pages {
              start
              end
            }
            abstract
          }
        }
      }
    }
  `);
  return allPapersYaml.edges.map(({ node }) => node);
}
